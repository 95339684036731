<template>
  <login-layout
    v-if="showLoginPage"
    page-class="login-page"
    pageTitle="SIGN IN"
    icon="user"
  >
    <div class="form-block-wrapper">
      <VeeForm
        @submit="submitForm"
        v-slot="{ errors }"
        class="form"
        style="position: relative;"
        novalidate
      >
        <div class="loader-wrapper" v-if="loader">
          <div class="loader"></div>
        </div>
        <transition name="fade">
          <div class="error-message" v-if="serverError">
            {{ serverError }}
          </div>
        </transition>
        <div class="form-group submit">
          <button
            class="btn primary btn-sso"
            type="button"
            :disabled="submitting"
            @click="signInWithAzure"
          >
            Sign in with SSO
          </button>
        </div>
        <div class="form-group input">
          <Field
            name="username"
            as="input"
            type="text"
            placeholder="Username"
            rules="required"
            autocapitalize="none"
            :disabled="submitting"
            autocomplete="on"
          />
          <span class="error-message" v-if="errors.username">
            {{ errors.username }}
          </span>
        </div>
        <div class="form-group input">
          <Field
            name="password"
            as="input"
            type="password"
            placeholder="Password"
            rules="required"
            autocapitalize="none"
            :disabled="submitting"
            autocomplete="on"
          />
          <span class="error-message" v-if="errors.password">
            {{ errors.password }}
          </span>
        </div>
        <div class="form-group submit">
          <button class="btn primary" type="submit" :disabled="submitting">
            Sign in
          </button>
        </div>
        <div class="form-group">
          <router-link to="/forgot-password" class="link"
            >Forgot <span>password?</span></router-link
          >
        </div>
        <screen-modal
          v-if="show"
          class="confirm-modal"
          type="success"
          :hide-timer="true"
          :confirm-action="submitForm"
          :confirm-button-label="'ok'"
          :cancel-button-label="'cancel'"
          :show="show"
          @close="show = false"
        >
          <h3 class="modal-title">Two-Factor Authentication</h3>
          <v-otp-input
            ref="otpInput"
            input-classes="otp-input"
            separator=""
            :num-inputs="6"
            :should-auto-focus="true"
            :is-input-num="true"
            :placeholder="['', '', '', '', '', '', '']"
          />
        </screen-modal>
      </VeeForm>
    </div>
  </login-layout>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapActions, mapState } from "vuex";
import LoginLayout from "@/layouts/LoginLayout";
import httpServiceAuth, {
  getSamlResponse,
  getSubdomain,
  getSsoErrorMessage,
  getAccessToken,
  clearAccessToken,
  saveAccessToken
} from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";

export default {
  name: "login",
  components: {
    LoginLayout,
    VOtpInput: defineAsyncComponent(() => import("vue3-otp-input"))
  },
  data() {
    return {
      show: false,
      submitting: false,
      serverError: "",
      hasErrors: false,
      showLoginPage: false,
      loader: false,
      encryptionKey: "jZrlXqIpCRvsaMU",
      outlookEmail: null
    };
  },
  computed: {
    ...mapState("auth", ["isOutlookPage"])
  },
  created() {
    window.addEventListener("message", this.readMessage);
    if (this.isOutlookPage) {
      parent.postMessage({ call: "getEmail" }, "*");
    }

    if (!getAccessToken()) {
      const search = window.location.search
        .replace("?redirect=/", "")
        .replace("?", "")
        .split("&");

      // check if searh has length and first element is not empty
      if (search.length && search[0]) {
        const token = search.find(element => element.indexOf("token") !== -1);
        const samlResponse = getSamlResponse();
        const errorMessage = getSsoErrorMessage();
        const addIn = search.find(element => element.indexOf("addin") !== -1);

        if (token) {
          const tokenValue = token.split("=")[1];
          if (tokenValue) {
            let payload = {
              token: tokenValue
            };
            if (addIn) {
              payload.addIn = addIn;
            }
            this.login(payload).catch(error => {
              this.hasErrors = true;
              const { data } = error.response;
              if (data && data.message) {
                this.serverError = data.message;
              }
            });
          }
        } else {
          this.showLoginPage = true;
        }

        if (samlResponse) {
          this.ssoLogin(samlResponse).catch(error => {
            this.handleLoginError(error.response);
          });
        }

        if (errorMessage) {
          this.serverError = errorMessage;
        }
      } else {
        this.showLoginPage = true;
      }
    } else {
      this.showLoginPage = true;
    }
  },
  methods: {
    ...mapActions("auth", ["login", "ssoLogin"]),
    readMessage(event) {
      if (event && event.data && event.data.email) {
        this.outlookEmail = event.data.email;
      }
    },
    encryptData(text) {
      // move text to base64 so we avoid special chars
      const base64 = btoa(text);

      // text string to array
      const arr = base64.split("");
      // array of password
      const arrPass = this.encryptionKey.split("");
      let lastPassLetter = 0;

      // encrypted string
      let encrypted = "";

      // encrypt
      for (let i = 0; i < arr.length; i++) {
        const letter = arr[i];

        const passwordLetter = arrPass[lastPassLetter];

        const temp = this.getLetterFromAlphabetForLetter(
          passwordLetter,
          letter
        );

        if (temp) {
          // concat to the final response encrypted string
          encrypted += temp;
        } else {
          // if any error, return null
          return null;
        }

        /*
     This is important: if we're out of letters in our 
     password, we need to start from the begining.
   */
        if (lastPassLetter == arrPass.length - 1) {
          lastPassLetter = 0;
        } else {
          lastPassLetter++;
        }
      }

      // We finally return the encrypted string
      return encrypted;
    },
    getLetterFromAlphabetForLetter(letter, letterToChange) {
      // this is the alphabet we know, plus numbers and the = sign
      const abc =
        "abcdefghijklmnopqrstuvwxyz0123456789=ABCDEFGHIJKLMNOPQRSTUVWXYZ";

      // get the position of the given letter, according to our abc
      const posLetter = abc.indexOf(letter);

      // if we cannot get it, then we can't continue
      if (posLetter == -1) {
        // console.log("Password letter " + letter + " not allowed.");
        return null;
      }
      // according to our abc, get the position of the letter to encrypt
      const posLetterToChange = abc.indexOf(letterToChange);

      // again, if any error, we cannot continue...
      if (posLetterToChange == -1) {
        // console.log("Password letter " + letter + " not allowed.");
        return null;
      }

      // let's build the new abc. this is the important part
      const part1 = abc.substring(posLetter, abc.length);
      const part2 = abc.substring(0, posLetter);
      const newABC = "" + part1 + "" + part2;

      // we get the encrypted letter
      const letterAccordingToAbc = newABC.split("")[posLetterToChange];

      // and return to the routine...
      return letterAccordingToAbc;
    },
    submitForm(values) {
      this.loader = true;
      if (values) {
        this.submitting = true;
        this.hasErrors = false;
        this.serverError = "";
        if (this.$refs.otpInput) {
          values._2fa_code = "";
          this.$refs.otpInput.otp.forEach(function(code) {
            values._2fa_code += code;
          });
        }
        this.login(values)
          .catch(error => {
            if (
              {}.propertyIsEnumerable.call(
                error.response.data.errors,
                "_2fa_code"
              )
            ) {
              this.show = true;
            } else {
              this.handleLoginError(error.response);
            }
          })
          .finally(() => {
            this.loader = false;
            this.submitting = false;
          });
      }
    },
    signInWithAzure() {
      clearAccessToken();
      this.loader = true;
      this.submitting = true;
      this.serverError = "";
      const subdomain = getSubdomain();
      if (this.isOutlookPage) {
        let emailEncrypted = this.encryptData(this.outlookEmail);
        httpServiceAuth
          .get(`${apiEndpoints.company.loginSsoOutlook}/${emailEncrypted}`)
          .then(response => {
            if (response && response.data && response.data.access_token) {
              saveAccessToken(response.data.access_token);
              this.$router.push({
                name: "r_outlook"
              });
            }
          })
          .catch(error => {
            this.handleLoginError(error.response);
          });
      } else {
        let url = `${apiEndpoints.master.sso}/all`;
        if (subdomain) {
          url = apiEndpoints.company.sso;
        }
        httpServiceAuth
          .get(url, { params: { addin: false } })
          .then(response => {
            if (
              response &&
              response.data &&
              typeof response.data === "string" &&
              response.data.length
            ) {
              window.location.href = response.data;
            }
          })
          .catch(error => {
            this.handleLoginError(error.response);
          })
          .finally(() => {
            this.loader = false;
            this.submitting = false;
          });
      }
    },
    handleLoginError(errorObject) {
      this.hasErrors = true;
      const { data, statusText } = errorObject;
      if ((data && data.message) || statusText) {
        if (data.errors) {
          const keys = Object.keys(data.errors);
          if (keys.length) {
            this.serverError = data.errors[keys[0]][0];
          } else {
            this.serverError = data.message || statusText;
          }
        } else {
          this.serverError = data.message || statusText;
        }
      }
    }
  }
};
</script>
<style>
.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 0;
}
.loader {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  display: block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
